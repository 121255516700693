import { useToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import App from "../App";
import ReactDOM from "react-dom";
import Cookies from "universal-cookie";
import Login from "./Login";

//alert for 401 message
export function UseAlert(props) {

    if (props === 401) {
        let cookies = new Cookies();
        cookies.remove("user_token_type", { path: '/' })
        cookies.remove("user_token", { path: '/' })
        localStorage.clear()
        sessionStorage.clear()
        ReactDOM.render(<Login />, document.getElementById('root'));
        return '';
    }
}
//alert with custom message
export function UseMessage(props) {

    const message = useToast({
        message: props,
        position: "bottomRight",
        class: "btn btn-outline-error",
        timeout: 3000,
        progressBar: true,
        icon: 'error',
        color: '#000000',
        messageColor: '#ffffff',
        closeOnClick: true
    });
    return message();
}

//alert with custom status error and message
export function UseAlertMessage(status, mess) {
    const message = useToast({
        message: mess,
        position: "bottomRight",
        class: "btn btn-outline-error",
        timeout: 3000,
        progressBar: true,
        icon: status == 200 ? 'success' : 'warning',
        // color: status == 200 ? '#00c64afa' : '#b90000',
        color: '#000000',
        messageColor: '#ffffff',
        closeOnClick: true
    });
    return message();
}
