import React, { useEffect, useState } from "react"
import { OpportunitiesCard } from "./OpportunitiesCard"
import { OppotunitiesOverview } from "./OpportunitiesOverview"
import { OppotunitiesInterested } from "./OpportunitiesInterested"
import { OpportunitiesService } from "../Services/Services"
import { LoadingComponentWeb } from "./LoadingComponent"
import { getTranslationByKey } from "../global"

export function OppotunitiesDetail(props) {
    const OS = new OpportunitiesService
    const [loading, setLoading] = React.useState(true)
    const [selectedCard, setSelectedCard] = React.useState(props.detailType === 'project' ? props.selectedProject : props.selectedExperience)
    const [selectedSection, setSelectedSection] = React.useState('overview')
    const [cardDetail, setCardDetail] = React.useState({})

    useEffect(() => {
        if (props.selectedOpportunity.id)
            if (props.userView)
                OS.getOpportunityById(props.selectedOpportunity.id).then((data) => {
                    setCardDetail(data)
                    setLoading(false)
                })
            else
                OS.getOpportunityByIdAdmin(props.selectedOpportunity.id).then((data) => {
                    setCardDetail(data)
                    setLoading(false)
                })
    }, [])

    const reload = () => {
        if (props.userView)
            OS.getOpportunityById(props.selectedOpportunity.id).then((data) => {
                setCardDetail(data)
                setLoading(false)
            })
        else
            OS.getOpportunityByIdAdmin(props.selectedOpportunity.id).then((data) => {
                setCardDetail(data)
                setLoading(false)
            })
    }

    const getData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = ("0" + new_date.getDate()).slice(-2).toString() + '/' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '/' + new_date.getFullYear().toString()
        return toStringDate
    }

    return (
        <div>
            {loading ?
                <LoadingComponentWeb />
                :
                <React.Fragment>
                    {(cardDetail.info.is_opportunity_admin && !props.userView) && window.innerWidth > 1000 ?
                        <div className="feed_buttons_container">
                            <div className={selectedSection === 'overview' ? "feed_button_selected bold" : "feed_button pointer"} onClick={() => setSelectedSection('overview')}>{getTranslationByKey('OPPORTUNITIES.LABLE.OVERVIEW')}</div>
                            <div className={selectedSection === 'interested' ? "feed_button_selected bold" : "feed_button pointer"} onClick={() => setSelectedSection('interested')}>{getTranslationByKey('OPPORTUNITIES.LABLE.INTERESTED')}</div>
                        </div>
                        :
                        ''}
                    <div>
                        {selectedSection === 'overview' ?
                            <OppotunitiesOverview handleDuplicateView={props.handleDuplicateView} userView={props.userView} handleRemoveOpportunity={props.handleRemoveOpportunity} selectedCard={selectedCard} detail={cardDetail} getData={getData} setUpdateView={props.setUpdateView} reload={reload} />
                            :
                            <OppotunitiesInterested handleDuplicateView={props.handleDuplicateView} userView={props.userView} handleRemoveOpportunity={props.handleRemoveOpportunity} selectedCard={selectedCard} detail={cardDetail} getData={getData} setUpdateView={props.setUpdateView} reloadTable={reload} reload={reload} />
                        }
                    </div>
                </React.Fragment>
            }
        </div>
    )
}
