import React, { useEffect, useState } from "react";
import { LoadingComponent, LoadingComponentWeb } from "./LoadingComponent";
import { InternalBackButton } from "./InternalBackButton";
import { BackButton } from "./BackButton";
import { customStyles, getTranslationByKey } from "../global";
import { BookshelfService } from "../Services/Services";
import Select from "react-select";

import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Dropdown, } from 'react-bootstrap';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import BookshelfDetailPage from "./BookshelfDetailPage";
import Quagga from 'quagga';

export default function Bookshelf(props) {
    const [loading, setLoading] = useState(false)
    const [selectedBook, setSelectedBook] = useState('')
    const [unavailable_books, setUnavailableBooks] = useState([])
    const [borrowed_books, setBorrowedBooks] = useState([])
    const [available_books, setAvailableBooks] = useState([])
    const [viewAllAvailable, setViewAllAvailable] = useState(false)
    const [viewAllUnavailable, setViewAllUnavailable] = useState(false)
    const [viewAll_borrowed, setViewAllBorrowed] = useState(false)
    const [available_books_viewAll, setAvailableBooksViewAll] = useState([])
    const [unavailable_books_viewAll, setUnavailableBooksViewAll] = useState([])
    const [borrowed_books_viewAll, setBorrowedBooksViewAll] = useState([])
    const [searching, setSearching] = useState('')
    const [result, setResult] = useState('')
    const [data, setData] = useState('')
    const [book_scan_info, setBook_scan_info] = useState('')
    const [stopStream, setStopStream] = useState(false)
    const [barcode_modal, setBarcode_modal] = useState(false)
    const [cam_loading, setCam_loading] = useState(false)
    const [isbn, setIsbn] = useState(false)
    const [books, setBooks] = useState([])
    const [categories, setCategories] = useState([])
    const [categoryDropdownName, setCategoryDropdownName] = useState('Category')
    const [isSearching, setIsSearching] = useState(false)
    const [viewAll_status, setViewAll_status] = useState('')
    const [selectedBookshelf, setSelectedBookshelf] = useState(null)
    const [bookshelfsList, setBookshelfsList] = useState([])
    const [bookshelfID, setBookshelfID] = useState('')
    const [selectedCategory, setSelectedCategory] = useState({})
    const [has_more_available_books, setHasMoreAvailableBooks] = useState(false)
    const [has_more_unavailable_books, setHasMoreUnavailableBooks] = useState(false)
    const [has_more_borrow_history_books, setHasMoreBorrowHistoryBook] = useState(false)
    const [quag, setQuag] = useState(null)

    let bs = new BookshelfService()
    var typingTimer;                //timer identifier
    var doneTypingInterval = 1000;

    useEffect(() => {
        let bookshelf_id = ''
        if (props.info && props.info.bookshelfs)
            bookshelf_id = props.info.bookshelfs.id
        setBookshelfID(bookshelf_id)
        setTimeout(() => {
            reload()
        }, 200)
    }, [props.info])

    useEffect(() => {
        if (!cam_loading && barcode_modal)
            setTimeout(() => {

                if (document.querySelector('#scanner-container')) {
                    // Inizializza Quagga per la scansione dei codici a barre
                    Quagga.init(
                        {
                            inputStream: {
                                name: 'Live',
                                type: 'LiveStream',
                                target: document.querySelector('#scanner-container'), // il contenitore del video
                            },
                            decoder: {
                                readers: ['ean_reader'], // Lettori di barcode supportati, incluso ISBN
                            },
                        },
                        (err) => {
                            if (err) {
                                console.error('Errore nell\'inizializzazione di Quagga:', err);
                                return;
                            }
                            Quagga.start();
                        }
                    );

                    // Funzione per elaborare il risultato della scansione
                    Quagga.onDetected((data) => {
                        const code = data.codeResult.code;
                        // Verifica se il codice è un ISBN (inizia con '978' o '979')
                        if (code.startsWith('978') || code.startsWith('979')) {
                            setDataInfo(code)
                            Quagga.stop();
                        }
                    });
                    setQuag(Quagga)


                    // Cleanup quando il componente viene smontato
                    return () => {
                        Quagga.stop();
                    };

                }
            }, 500);
        else if (Quagga?.type && !barcode_modal)
            Quagga.stop()
    }, [barcode_modal]);


    const handleViewAll = (val, books) => {
        if (val !== 'back')
            document.getElementById('sidebar').style.display = 'none'
        if (val === 'back')
            document.getElementById('sidebar').style.display = 'block'
        switch (val) {
            case getTranslationByKey('BS.HOME.AVAILABLE'): {
                if (isSearching) {
                    setLoading(false)
                    setViewAllAvailable(true)
                    setViewAllUnavailable(false)
                    setViewAllBorrowed(false)
                    setViewAll_status('available')
                    search(searching, 'available')
                    setSearching('')
                } else {
                    bs.getAvailableBooksByID(selectedBookshelf.value).then((data) => {
                        setAvailableBooksViewAll(data)
                        setViewAllAvailable(true)
                        setViewAllUnavailable(false)
                        setViewAllBorrowed(false)
                        setViewAll_status('available')
                        setSearching('')
                    })
                }
                break;
            }
            case getTranslationByKey('BS.HOME.UNAVAILABLE'): {
                if (isSearching) {
                    setLoading(false)
                    setUnavailableBooksViewAll(unavailable_books)
                    setViewAllAvailable(false)
                    setViewAllUnavailable(true)
                    setViewAllBorrowed(false)
                    setViewAll_status('unavailable')
                    search(searching, 'unavailable')
                    setSearching('')
                } else {
                    bs.getUnavailableBooksByID(selectedBookshelf.value).then((data) => {
                        setUnavailableBooksViewAll(data)
                        setViewAllAvailable(false)
                        setViewAllUnavailable(true)
                        setViewAllBorrowed(false)
                        setViewAll_status('unavailable')
                        setSearching('')
                    })
                }
                break;
            }
            case getTranslationByKey('BS.HOME.BORROWEDBYME'): {
                if (isSearching) {
                    setLoading(false)
                    setViewAllAvailable(false)
                    setViewAllUnavailable(false)
                    setViewAllBorrowed(true)
                    setAvailableBooksViewAll([])
                    setUnavailableBooksViewAll([])
                    setBorrowedBooksViewAll(books)
                    setViewAll_status('borrowed')
                    search(searching, 'borrowed')
                    setSearching('')
                } else {
                    bs.getBorrowHistory(selectedBookshelf.value).then(() => {
                        setViewAllAvailable(false)
                        setViewAllUnavailable(false)
                        setViewAllBorrowed(true)
                        setAvailableBooksViewAll([])
                        setUnavailableBooksViewAll([])
                        setBorrowedBooksViewAll(books)
                        setViewAll_status('borrowed')
                        setSearching('')
                    })
                }
                break;
            }
            case 'back':
                resetViewAll()
                setAvailableBooksViewAll([])
                setUnavailableBooksViewAll([])
                setBorrowedBooksViewAll([])
                setIsSearching(false)
                setSearching('')
                setCategoryDropdownName('Category')
                setViewAll_status('')
                setSelectedCategory({ id: '', label: '' })
                reload()
                break;
            default: break


        }

    }
    const resetViewAll = () => {
        setViewAllAvailable(false)
        setViewAllUnavailable(false)
        setViewAllBorrowed(false)
    }

    //reload data
    const reload = () => {
        setLoading(true)
        if (document.getElementById('sidebar'))
            document.getElementById('sidebar').style.display = 'block'

        let id = ''
        if (!selectedBookshelf) {

            if (!bookshelfID && props.info && props.info.bookshelfs)
                id = props.info.bookshelfs.id
            else
                id = bookshelfID

            if (props.subModuleId)
                id = props.subModuleId
        } else
            id = selectedBookshelf.value

        bs.getBookshelfOptions(id).then((data) => {
            setCategories(data.categories)
            let tmp = []
            let routingIdModule = null
            data.bookshelfs.map((el) => {
                if (props.subModuleId == el.id) {
                    routingIdModule = { value: el.id, label: el.name }
                }
                tmp.push({ value: el.id, label: el.name })
            })
            setBookshelfsList(tmp)
            if (!selectedBookshelf) {
                if (props.subModuleId && routingIdModule) {
                    setSelectedBookshelf({ value: routingIdModule.value, label: routingIdModule.label })
                } else if (props.info && props.info.bookshelfs) {
                    console.log("qui 2")
                    let t = { value: props.info.bookshelfs.id, label: props.info.bookshelfs.name }
                    setSelectedBookshelf({ ...t })
                } else {
                    setSelectedBookshelf({ value: data.default_bookshelf.id, label: data.default_bookshelf.name })
                }
            }
            bs.getBookshelfByID(id).then((data) => {

                setAvailableBooks(data.available_books);
                setUnavailableBooks(data.unavailable_books);
                setBorrowedBooks(data.borrow_history);
                setHasMore(data);
                setLoading(false)
            })
        })
    }

    const setHasMore = (data) => {
        setHasMoreAvailableBooks(data.has_more_available_books)
        setHasMoreUnavailableBooks(data.has_more_unavailable_books)
        setHasMoreBorrowHistoryBook(data.has_more_borrow_history)
    }

    const deselectBook = () => {
        if (!viewAllAvailable && !viewAllUnavailable && !viewAll_borrowed)
            if (document.getElementById('sidebar'))
                document.getElementById('sidebar').style.display = 'block'
        setSelectedBook('')
        closeBarCodeModal()
    }

    const setSelectedBookInfo = (val) => {
        if (document.getElementById('sidebar'))
            document.getElementById('sidebar').style.display = 'none'
        setSelectedBook(val)
    }

    const closeBarCodeModal = () => {
        setBarcode_modal(false)
        setResult(null)
        setData(null)
        setBook_scan_info(null)
        setStopStream(false)
        setCamLoading(false)
        setIsbn(null)
        if (Quagga && Quagga.running)
            Quagga.stop()
    }
    const openBarCodeModal = () => {
        setCamLoading(false)
        setData(null)
        setBook_scan_info(null)
        setStopStream(false)
        setResult(null)
        setSelectedBook(null)
        setTimeout(() => {
            setBarcode_modal(true)
        }, 1000);

    }

    const returnBook = () => {
        bs.returnBook(selectedBook.borrow_id).then(() => {
            setSelectedBook('')
            resetViewAll()
            setViewAll_status('')
            setAvailableBooksViewAll([])
            setUnavailableBooksViewAll([])
            setBorrowedBooksViewAll([])
            if (window.innerWidth < 1000)
                document.getElementById('sidebar').style.display = 'block'
            reload()
        })
    }

    const resetSearch = () => {
        if (searching !== '') {
            setSearching('')
            setIsSearching(false)
            setBooks([])
            setTimeout(() => {
                search('')
            }, 500)
        }
    }

    const search = (val, status) => {
        let searchText = searching
        if (val === '')
            searchText = val

        let options = {}

        setIsSearching(true)
        setLoading(true)

        if (viewAllAvailable || viewAllUnavailable || viewAll_borrowed || status) {
            options = {
                "categoryID": selectedCategory.id,
                "search_text": searchText,
                "status": status ? status : viewAll_status
            }

            bs.searchV2(options, selectedBookshelf.value).then((data) => {
                setFilteredBooksData(data, status)
                setLoading(false)
            })
        } else {

            options = {
                "categoryID": '',
                "search_text": searchText
            }

            bs.searchAll(options, selectedBookshelf.value).then((data) => {
                setAvailableBooks(data.available_books);
                setUnavailableBooks(data.unavailable_books)
                setBorrowedBooks(data.borrow_history);
                setLoading(false)
            })
        }
    }

    const borrowBook = () => {
        bs.borrow(selectedBookshelf.value, selectedBook.id).then(() => {
            closeBarCodeModal()
            setSelectedBook('')
            resetViewAll()
            setViewAll_status('')
            setAvailableBooksViewAll([])
            setUnavailableBooksViewAll([])
            setBorrowedBooksViewAll([])
            if (window.innerWidth < 1000)
                document.getElementById('sidebar').style.display = 'block'
            reload()
        })
    }

    const notifyMe = () => {
        bs.notifyMe(selectedBookshelf.value, selectedBook.id).then(() => {
            let sel = selectedBook
            if (selectedBook.is_followed)
                sel.is_followed = false
            else
                sel.is_followed = true
            setSelectedBookInfo(sel)
            if (window.innerWidth < 1000)
                document.getElementById('sidebar').style.display = 'block'
            reload()
        })
    }

    const getFilteredSearch = () => {
        /* funzione per la barra di ricerca dei books */
        var searchingList = ''
        if (viewAllAvailable) {
            searchingList = available_books_viewAll.map((book, index) =>
                <React.Fragment>
                    <div key={"searching_" + index} className="book_cover_img pointer" Style={"background-image: url('" + book.image_url + "');"} onClick={() => setSelectedBookInfo(book)}></div>
                </React.Fragment>
            )
        } else if (viewAllUnavailable) {
            searchingList = unavailable_books_viewAll.map((book, index) =>
                <React.Fragment>
                    <div key={"searching_" + index} className="book_cover_img pointer" Style={"background-image: url('" + book.image_url + "');"} onClick={() => setSelectedBookInfo(book)}></div>
                </React.Fragment>
            )
        } else if (viewAll_borrowed) {
            searchingList = borrowed_books_viewAll.map((book, index) =>
                <React.Fragment>
                    <div key={"searching_" + index} className="book_cover_img pointer" Style={"background-image: url('" + book.image_url + "');"} onClick={() => setSelectedBookInfo(book)}></div>
                </React.Fragment>
            )
        }
        return searchingList
    }

    const handleSearch = (e) => {
        setSearching(e.target.value)
        if (searching == '')
            setBooks([])
    }

    const selectCategory = (category) => {
        let category_id = ''

        if (category === '') {
            category_id = ''
            setCategoryDropdownName('Category')
            setSelectedCategory({})
            setLoading(true)
        } else {
            setCategoryDropdownName(category.name)
            setSelectedCategory({ id: category.id, name: category.name })
            setLoading(true)
            category_id = category.id
        }

        let options = {}
        if (viewAllAvailable) {
            options = {
                "categoryID": category_id,
                "search_text": searching,
                "status": 'available'
            }
        } else if (viewAllUnavailable) {
            options = {
                "categoryID": category_id,
                "search_text": searching,
                "status": 'unavailable'
            }
        } else if (viewAll_borrowed) {
            options = {
                "categoryID": category_id,
                "search_text": searching,
                "status": 'borrowed'
            }
        }

        bs.searchV2(options, selectedBookshelf.value).then((data) => {
            setFilteredBooksData(data)
            setLoading(false)
        })
    }

    const setFilteredBooksData = (data, status) => {
        if (viewAllAvailable || status === 'available') {
            setAvailableBooksViewAll(data)
        } else if (viewAllUnavailable || status === 'unavailable') {
            setUnavailableBooksViewAll(data)
        } else if (viewAll_borrowed || status === 'borrowed') {
            setBorrowedBooksViewAll(data)
        }
        setAvailableBooks(data)
    }

    //it returns graphic line of books
    const getBookshelfLine = (flag, list, listViewAll, title, has_more) => {

        return (
            <>
                {flag ?
                    <React.Fragment>
                        {window.innerWidth < 1000 ?
                            <div className="bookshelf_section_title_container flex" Style={'width:90%'}>

                                <>
                                    <div className="bookshelf_section_title_container flex" Style={'display:flex; place-content:space-between'}>
                                        <div className="bookshelf_section_title">{title}</div>
                                        {!flag && list.length > 0 ? <div className="bookshelf_section_title pointer" onClick={() => handleViewAll(title, list)}>{getTranslationByKey('BS.HOME.VIEWALL')}</div>
                                            : ''}
                                    </div>
                                    <div className="category_margin" Style={"margin-left: auto;"}>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-bookshelf">
                                                {categoryDropdownName}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => selectCategory('')} key={''}>{getTranslationByKey("BOOKSHELF.ALLCATEGORIES")}</Dropdown.Item>
                                                {categories.map((c) =>
                                                    <Dropdown.Item onClick={() => selectCategory({ id: c.id, name: c.name })} key={c.id}>{c.name}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </>
                            </div>
                            : ''}
                    </React.Fragment>
                    : ''}

                <div className="bookshelf_line" >
                    {/*view all not searching*/}

                    {window.innerWidth > 1000 || (window.innerWidth < 1000 && !viewAll_status) ?
                        <div className="bookshelf_section_title_container flex" Style={'display:flex; place-content:space-between'}>
                            <div className="bookshelf_section_title">{title}</div>
                            {!flag && list.length > 0 ? <div className="bookshelf_section_title pointer" onClick={() => handleViewAll(title, list)}>{getTranslationByKey('BS.HOME.VIEWALL')}</div>
                                : ''}
                        </div> : ''}
                    <div className={flag ? "bookshelf_section_books_all" : "bookshelf_section_books"} >
                        <div className="bookshelf_section_books_container flex" Style={flag ? 'flex-wrap: wrap; justify-content:center;padding-bottom:60px' : ''}>
                            {flag ?
                                listViewAll.map((book, index) =>
                                    <div key={title + "_" + index} className={title === getTranslationByKey('BS.HOME.BORROWEDBYME') && !book.borrowed ? "book_cover_img pointer past_book" : "book_cover_img pointer "} Style={"background-image: url('" + book.image_url + "');"} onClick={() => setSelectedBookInfo(book)}></div>
                                )
                                :
                                (list.map((book, index) =>
                                    <div key={title + "_" + index} className={title === getTranslationByKey('BS.HOME.BORROWEDBYME') && !book.borrowed ? "book_cover_img pointer past_book" : "book_cover_img pointer "} Style={"background-image: url('" + book.image_url + "');"} onClick={() => setSelectedBookInfo(book)}></div>

                                ))}
                            {flag && listViewAll.length == 0 ? <div>{getTranslationByKey('BS.HOME.NOBOOKSFOUND')}</div> : (!flag && list.length == 0 ? <div>{getTranslationByKey('BS.HOME.NOBOOKSFOUND')}</div> : '')}
                            {!flag && has_more && list.length > 0 ?
                                <div className="relative">
                                    {/* <div key={title + "_-1"} className="book_cover_img book_cover_img_view_all relative pointer" onClick={() => handleViewAll(title, list)}></div>
                                <div className="bookshelf_card_view_all pointer" ></div>*/}
                                </div>
                                : ''}
                        </div>
                    </div>
                    {flag ?
                        <div Style={"width: 100%; place-content: center !important; display: flex;"} >
                        </div>
                        : null}
                </div ></>
        )
    }

    const setCamLoading = async (val) => {
        setCam_loading(val)
    }

    const retry = () => {

        setCamLoading(false)
        setData(null)
        setBook_scan_info(null)
        setStopStream(false)
        setResult(null)
        setSelectedBook(null)
        // Inizializza Quagga per la scansione dei codici a barre
        Quagga.init(
            {
                inputStream: {
                    name: 'Live',
                    type: 'LiveStream',
                    target: document.querySelector('#scanner-container'), // il contenitore del video
                },
                decoder: {
                    readers: ['ean_reader'], // Lettori di barcode supportati, incluso ISBN
                },
            },
            (err) => {
                if (err) {
                    console.error('Errore nell\'inizializzazione di Quagga:', err);
                    return;
                }
                Quagga.start();
            }
        );

        // Funzione per elaborare il risultato della scansione
        Quagga.onDetected((data) => {
            const code = data.codeResult.code;
            // Verifica se il codice è un ISBN (inizia con '978' o '979')
            if (code.startsWith('978') || code.startsWith('979')) {
                setDataInfo(code)
                Quagga.stop();
            }
        });
    }

    const borrow = () => {
        let bookshelf_id = ''
        props.info.additional_menu.map((m) => {
            if (m.text === 'bookshelf') {
                bookshelf_id = m.bookshelfs.id
            }
        })
        let bs = new BookshelfService()
        bs.borrow(bookshelf_id, book_scan_info.id).then(() => {
            closeBarCodeModal()
        })
    }

    const setBookInfo = (data) => {
        if (data.title) {
            setBook_scan_info(data)
            setSelectedBook(data)
            closeBarCodeModal()
        }
        setBook_scan_info(data)
    }

    const setDataInfo = (data) => {
        if (data !== "Not Found" && !result) {
            setCamLoading(true)
            setData(data)
            setResult(data)
            setIsbn(data)
            setTimeout(() => {
                setStopStream(true)
            }, 1000);

            let tosend = {
                isbn: data
            }


            bs.getInfoByIsbn(selectedBookshelf.value, tosend).then((data) => {
                setBookInfo(data)
                setSelectedBook(data)
            }).catch((err) => {
                setBookInfo('not_found')
                setSelectedBook(null)

            }).finally(() => {
                setCamLoading(false)
                //Quagga.stop()
            })
        }
        else {
            setCamLoading(false)
        }
    }

    const handleChangeBookshelf = async (bookshelf) => {
        setSearching('')
        if (bookshelf.value != selectedBookshelf.value) {
            setLoading(true)
            setSelectedBookshelf(bookshelf)
            setBookshelfID(bookshelf.value)
            bs.getBookshelfByID(bookshelf.value).then((data) => {
                setAvailableBooks(data.available_books);
                setUnavailableBooks(data.unavailable_books);
                setBorrowedBooks(data.borrow_history);
                setHasMore(data);
                setLoading(false)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            search()
        }
    }

    useEffect(() => {
        addListener()

        return () => {
            removeListener()
        }
    }, [selectedBookshelf, viewAllAvailable, viewAllUnavailable, viewAllUnavailable, viewAll_status])

    const addListener = () => {
        window.addEventListener("keyup", addManual, false);
        window.addEventListener("keydown", clear, false);
    }

    const removeListener = () => {
        window.removeEventListener("keyup", addManual, false);
        window.removeEventListener("keydown", clear, false);
    }

    function addManual() {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(doneTypingManual, doneTypingInterval);
    }

    function clear() {
        clearTimeout(typingTimer);
    }

    function doneTypingManual() {
        let element = document.querySelector('#bookshelf_search_input')
        if (element.value) {
            if (selectedBookshelf) {
                if (viewAllAvailable || viewAllUnavailable || viewAll_borrowed) {
                    let options = {
                        "categoryID": selectedCategory.id,
                        "search_text": element.value,
                        "status": viewAllAvailable ? 'available' : (viewAllUnavailable ? 'unavailable' : 'borrowed')
                    }

                    bs.searchV2(options, selectedBookshelf.value).then((data) => {
                        setFilteredBooksData(data, viewAllAvailable ? 'available' : (viewAllUnavailable ? 'unavailable' : 'borrowed'))
                        setLoading(false)
                    })
                } else {
                    let options = {
                        "categoryID": '',
                        "search_text": element.value
                    }
                    bs.searchAll(options, selectedBookshelf.value).then((data) => {
                        setAvailableBooks(data.available_books);
                        setUnavailableBooks(data.unavailable_books)
                        setBorrowedBooks(data.borrow_history);
                        setLoading(false)
                    })
                }
            }
        }
    }
    return (
        loading ? <LoadingComponentWeb /> :
            <React.Fragment>
                {window.innerWidth > 1000 && (selectedBook !== '' || viewAll_status !== '') ?
                    <InternalBackButton name={'back_button_component_dx  flex'} key='selected_book' backButtonFunction={selectedBook !== '' ? deselectBook : () => handleViewAll('back')} index={99} /> : ''}
                {window.innerWidth < 1000 ?
                    <BackButton key='generic_back' backButtonFunction={props.backToMenu} />
                    : ''}
                <div className="bookshelf bookshelf_margin_mobile">
                    {viewAll_status && window.innerWidth < 1000 ? <BackButton backButtonFunction={() => handleViewAll('back')} index={210} /> : ''}

                    {/* --- DetailPage --- */}
                    {selectedBook ?
                        <React.Fragment>
                            {window.innerWidth < 1000 ? <BackButton backButtonFunction={deselectBook} index={210} /> : ''}
                            <div className="bookshelf_detail_container">
                                <div className="bookshelf_detail_header">
                                    <div className="bookshelf_detail_header_container flex" Style="padding: 20px;">
                                        <div className="bookshelf_detail_cover_container">
                                            <div className="bookshelf_detail_cover" Style={"background-image: url('" + selectedBook.image_url + "');"}></div>
                                            <div Style={"text-align: center; text-align: -webkit-center; "}>
                                                {selectedBook.borrowed ?
                                                    <div className="bookshelf_detail_button pointer" onClick={returnBook}>{getTranslationByKey('BS.DETAIL.RETURNBOOK')}</div>
                                                    : selectedBook.available ?
                                                        <div className="bookshelf_detail_button pointer" onClick={borrowBook}>{getTranslationByKey('BS.DETAIL.BORROWBOOK')}</div>
                                                        :
                                                        (selectedBook.is_followed ?
                                                            <div className="bookshelf_detail_button pointer" onClick={notifyMe}>{getTranslationByKey('BS.DETAIL.STOPNOTIFY')}</div>
                                                            :
                                                            <div className="bookshelf_detail_button pointer" onClick={notifyMe}> {getTranslationByKey('BS.DETAIL.NOTIFY')}</div>)
                                                }

                                            </div>
                                        </div>
                                        <BookshelfDetailPage selectedBook={selectedBook} />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment >
                        :
                        <React.Fragment>
                            {viewAll_status ? '' :
                                <div className="share_to_go_select_width pt-4">
                                    <Select
                                        value={selectedBookshelf && selectedBookshelf.value ? selectedBookshelf : ''}
                                        isClearable={false}
                                        isSearchable={false}
                                        inputProps={{ readOnly: true }}
                                        onChange={(e) => handleChangeBookshelf(e)}
                                        options={bookshelfsList}
                                        className="opportunities_select s2g_select"
                                        styles={customStyles}
                                    />
                                </div>}
                            <div className="bookshelf_line bookshelf_line_viewAll" >
                                <div className="bookshelf_search flex" Style={window.innerWidth < 1000 ? "width: 100%; margin-top: 5px;" : (viewAll_status !== '' ? 'width: 97%; margin-top: 12px;' : "width: 62%; justify-self: center;    margin-left: 4%; ")}>
                                    <input className="bookshelf_search_input" id='bookshelf_search_input' onKeyDown={handleKeyDown} placeholder={getTranslationByKey("DESKBOOKING.MAP.SEARCH")} onChange={(e) => handleSearch(e)} value={searching} />
                                    <div id="search_reset_bookshelf" className="pointer" onClick={resetSearch}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    {/*  <div >
                                        <div className="search_button_bookshelf pointer" onClick={search}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                            </svg>
                                        </div>
                                    </div>*/}
                                    <div id="barcode_search" className=" pointer">
                                        <svg onClick={() => openBarCodeModal()} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
                                            <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
                                        </svg>
                                    </div>
                                    {viewAll_status && window.innerWidth > 1000 ?

                                        <div className="ml-5">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-bookshelf">
                                                    {categoryDropdownName}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => selectCategory('')} key={''} >{getTranslationByKey("BOOKSHELF.ALLCATEGORIES")}</Dropdown.Item>
                                                    {categories.map((c) =>
                                                        <Dropdown.Item onClick={() => selectCategory({ id: c.id, name: c.name })} key={c.id}>{c.name}</Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> : ''}
                                </div>
                            </div>
                            <div className={viewAll_status ? "" : "bookshelf_container"}>
                                {isSearching && viewAll_status ?
                                    <div className="bookshelf_line" Style={"max-height: 500px;"}>

                                        <div className="bookshelf_section_books_all" Style={'max-height:calc(100vh - 300px);overflow:auto'}>
                                            {getFilteredSearch().length > 0 ?
                                                getFilteredSearch()
                                                :
                                                <div>{getTranslationByKey('BS.HOME.NOBOOKSFOUND')}</div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <React.Fragment>
                                        {!viewAllUnavailable && !viewAll_borrowed ?
                                            getBookshelfLine(viewAllAvailable, available_books, available_books_viewAll, getTranslationByKey('BS.HOME.AVAILABLE'), has_more_available_books)
                                            : null}
                                        {!viewAllAvailable && !viewAll_borrowed ?
                                            getBookshelfLine(viewAllUnavailable, unavailable_books, unavailable_books_viewAll, getTranslationByKey('BS.HOME.UNAVAILABLE'), has_more_unavailable_books)
                                            : null}
                                        {!viewAllAvailable && !viewAllUnavailable ?
                                            getBookshelfLine(viewAll_borrowed, borrowed_books, borrowed_books_viewAll, getTranslationByKey('BS.HOME.BORROWEDBYME'), has_more_borrow_history_books)
                                            : null}
                                    </React.Fragment>
                                }
                            </div>

                            <Dialog
                                open={barcode_modal}
                                onClose={closeBarCodeModal}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth
                                maxWidth="sm"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <Typography className="gucci-text-bold" variant="h5" style={{ fontSize: '16px', width: '80%', marginLeft: '15px' }}>
                                        {getTranslationByKey("BS.SCAN.TITLE")}
                                    </Typography>
                                    <Typography className="gucci-text-bold" variant="h5" style={{ fontSize: '13px', width: '90%', marginLeft: '15px', marginTop: '15px', marginRight: '15px' }}>
                                        {getTranslationByKey("BS.DETAIL.SCANBOOK.HELP")}
                                    </Typography>
                                    <div class='close_scan' onClick={closeBarCodeModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                        </svg>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    <div className={!result ? "camera_container" : "camera_container_after"}>



                                        {!cam_loading ?
                                            <div id="scanner-container" style={{ width: '100%', height: result ? '0px' : '45vh' }}>                                    </div>
                                            : <LoadingComponent />}

                                    </div>
                                    {book_scan_info && book_scan_info !== 'not_found' ?
                                        <React.Fragment>
                                            <div className="gucci-text-bold borrowing_text">{getTranslationByKey('BS.DETAIL.ALERT.TITLE2')}</div>
                                            <div className="gucci-text borrowing_subtext">{book_scan_info.title}{book_scan_info.author}</div>
                                        </React.Fragment>
                                        : (book_scan_info === 'not_found' ?
                                            <div className="gucci-text-bold borrowing_subtext mt-70">{getTranslationByKey('BS.SCAN.NOBOOK')}</div>
                                            : '')}
                                    {result ?

                                        <div className="flex" Style={"width: 100%; margin-top: 5%;justify-content: center;"}>
                                            <div className="bookshelf_button_retry" onClick={retry}>{getTranslationByKey('Scan another book')}</div>

                                        </div>
                                        : ''}

                                </DialogContent>

                            </Dialog>
                        </React.Fragment >
                    }
                </div >
            </React.Fragment >
    )
}