import { getTranslationByKey } from "../global";
import DOMPurify from 'dompurify'

export default function BookshelfDetailPage(props) {

    const getDate = (string) => {
        var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(string).toLocaleDateString([], options);
    }

    return (<div className="bookshelf_detail_title_container">
        <div Style={"margin-bottom: 10px;"}>
            <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.TITLE.KEY')}</div>
            <div className="bookshelf_detail_subtitle">{props.selectedBook.title}</div>
        </div>
        <div Style={"margin-bottom: 10px;"}>
            <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.AUTHOR.KEY')}</div>
            <div className="bookshelf_detail_subtitle">{props.selectedBook.author}</div>
        </div>
        <div Style={"margin-bottom: 10px;"}>
            <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.STAUS.KEY')}</div>
            <div className="bookshelf_detail_subtitle">{props.selectedBook.borrowed ? 'Borrowed' : props.selectedBook.available ? 'Available' : 'Unavailable'}</div>
        </div>
        {props.selectedBook.first_expected_return_date ?
            <div Style={"margin-bottom: 10px;"}>
                <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.STATUS.EXPECTEDRETURNDATE')}</div>
                <div className="bookshelf_detail_subtitle">{getDate(props.selectedBook.first_expected_return_date)}</div>
            </div>
            : null}
        <div Style={"margin-bottom: 10px;"}>
            <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.ISBN.KEY')}</div>
            <div className="bookshelf_detail_subtitle">{props.selectedBook.isbn}</div>
        </div>


        <div Style={"margin-bottom: 10px; margin-top: 10px;"}>
            <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.DESCRIPTION.KEY')}</div>
            <div className="bookshelf_detail_description">{props.selectedBook.description}</div>
        </div>

        <div Style={"margin-bottom: 10px;"}>
            <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.CATEGORY.KEY')}</div>
            <div className="bookshelf_detail_description">{props.selectedBook.category.name}</div>
        </div>
        {props.selectedBook.borrowed ?
            <div className="flex">
                <div Style={"margin-bottom: 10px;"}>
                    <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.RENTALDATE.KEY')}</div>
                    <div className="bookshelf_detail_description" Style={"text-transform: capitalize;"}>{getDate(props.selectedBook.borrowed_from)}</div>
                </div>
                <div className="bookshelf_rental_exp_date">
                    <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.RENTALEXPDATE.KEY')}</div>
                    <div className="bookshelf_detail_description" Style={"text-transform: capitalize;"}>{getDate(props.selectedBook.estimated_return_date)}</div>
                </div>
            </div>
            : ''}
        <div Style={"margin-bottom: 10px;"}>
            <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.PROVIDEDBY.KEY')}</div>
            <div className="bookshelf_detail_description">{props.selectedBook.provided_by}</div>
        </div>

        <div Style={"margin-bottom: 10px;"}>
            <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.AVAILABLECOPY.KEY')}</div>
            <div className="bookshelf_detail_description">{props.selectedBook.available_copies}</div>
        </div>
        <div Style={"margin-bottom: 10px;"}>
            <div className="bookshelf_detail_title">{getTranslationByKey('BS.DETAIL.TOTOALCOPY.KEY')}</div>
            <div className="bookshelf_detail_description">{props.selectedBook.total_copies}</div>
        </div>
        <div Style={"margin-top: 20px;"}>
            <a href="mailto:ccms-bit@gucci.com">
                <div Style={"text-align: center; text-align: -webkit-center;    "} dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(getTranslationByKey('BS.DETAIL.DONATEBOOK'))
                }} ></div>
            </a>
        </div>

    </div>)
}