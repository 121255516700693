import React, { useEffect, useState } from "react"
import { BusinessTravelService, DeskService, LoginService, WellnessService } from "../Services/Services"
import { getAnalytics, logEvent } from "firebase/analytics";
import { getTranslationByKey } from "../global";
import { Navbar } from "./Navbar";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Pathlist } from "./Pathlist";
import { Profile } from "./Profile";
import { Sidebar } from "./Sidebar";
import { CentralComponent } from "./CentralComponent";
import Cookies from "universal-cookie";
import { msalInstance } from "../authConfig";
import { WebPathlist } from "./WebPathlist";
import { WebPathlistSingleModule } from "./WebPathlistSingleModule";
import { SidebarWeb } from "./SidebarWeb";

export default function NewHomepage(props) {
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState('')
    const [selected_office, setSelectedOffice] = useState('')
    const [user, setUser] = useState('')
    const [whatsNew, setWhatsNewPopup] = useState(false)
    const [whatsNewPages, setWhatsNewPages] = useState(0)
    const [selectedWhatsNewPage, setSelectedWhatsNewPage] = useState(0)
    const [notifications, setNotifications] = useState([])
    const [unreadNotifications, setUnreadNotifications] = useState(0)
    const [notificationBar, setNotificationBar] = useState(false)
    const [openedOne, setOpenedOne] = useState(false)
    const [profileShow, setProfileShow] = useState(false)
    const [custom_office, setCustomOffice] = useState(null)
    const [pathlist_value, setPathlistValue] = useState(null)
    const [hidden, setHidden] = useState(false)
    const [tutorial, setTutorial] = useState([])
    const [tutorialPopup, setTutorialPopup] = useState(false)
    const [selectedTutorialPage, setSelectedTutorialPage] = useState(0)
    const [isEvent, setIsEvent] = useState(false)
    const [webPathlist, setWebPathlist] = useState([])
    const [isOtherLocation, setIsOtherLocation] = useState(false)
    const [webPathlistSelected, setWebPathlistSelected] = useState(null)
    const [comingFrom, setComingFrom] = useState('')
    const [routingObj, setRoutingObj] = useState(null)
    const [reload, setReload] = useState('no')
    const [subModuleId, setSubModuleId] = useState(null)

    let ws = new WellnessService()
    let ds = new DeskService()
    let ls = new LoginService()
    let bs = new BusinessTravelService()

    useEffect(() => {
        setLoading(true)
        if (props.from === 'map') {
            setUser(props.user)
            setSelected('offices')
            setSelectedOffice(props.office)

        } else {

            if (!sessionStorage.getItem('translation')) {
                ds.getTranslation().then(() => {
                    ws.getUserInfov4().then((res) => {
                        res.event = null
                        setUser(res)
                        ws.getWhatsNew().then((data) => {
                            setWhatsNew(data)

                            ls.getNotifications().then((res) => {
                                setNotifications(res)
                            })
                            getNotificationsNumber()
                        })
                    })
                })
            }
            else {
                ws.getUserInfov4().then((res) => {
                    res.event = null
                    setUser(res)
                    ws.getWhatsNew().then((data) => {
                        setWhatsNew(data)

                        ls.getNotifications().then((res) => {
                            setNotifications(res)
                        })
                        getNotificationsNumber()
                    })
                })
            }
        }
        window.scrollTo(0, 0, 'smooth')
        window.addEventListener('click', verify)
    }, [])

    const getNotificationsNumber = () => {
        ls.getNotificationsNumber().then((res) => {
            setUnreadNotifications(res.unread_notifications)
        })
    }

    const verify = (e) => {
        if (document.getElementById('notificationBar') && !document.getElementById('notificationBar').contains(e.target) && document.getElementById('notificationIcon') && !document.getElementById('notificationIcon').contains(e.target)) {
            notificationBarStatus(false)
        }
    }

    const notificationBarStatus = (val) => {
        setUnreadNotifications(0)
        setNotificationBar(val)
        setOpenedOne(val ? true : false)
        ls.readNotifications()
    }

    const openProfile = () => {
        setProfileShow(true)

        let analytics = ''
        if (window.location.hostname === 'gucciforyou.gucci.com') {
            analytics = getAnalytics(props.app)
            logEvent(analytics, 'NavigateToUserProfile');
        }
    }

    const setWhatsNew = (data) => {
        if (data) {
            setWhatsNewPopup(true)
            setWhatsNewPages(data)
        }
    }

    const backToOffice = () => {
        setSelected('offices')
        setLoading(false)
    }

    const download = (value) => {
        ws.downloadPdf(value)
    }

    //download file api
    const getDocument = (url) => {
        setLoading(true)

        bs.getDocument(url, true).finally(() => {
            setLoading(false)
        })
    }

    const logout = () => {
        let cookies = new Cookies();
        cookies.remove("user_token_type", { path: '/' })
        cookies.remove("user_token", { path: '/' })
        localStorage.clear()
        sessionStorage.clear()
        msalInstance.logoutRedirect().catch(e => {
        });
    }

    const select = (val, o, where) => {
        /*
        ogni volta che seleziono una voce di menu svuoto il tutorial
        per evitare di avere tutorial di moduli selezionati in precedenza
        */
        setTutorial([])
        setWebPathlistSelected(null)
        //ripulisco is event
        setIsEvent(false)
        setProfileShow(false)
        setLoading(true)
        let analytics = ''
        if (window.location.hostname === 'gucciforyou.gucci.com') {
            analytics = getAnalytics(props.app)
        }
        if (o && o.text && o.text === 'wellness_regulations') {
            download('RegulationOfUse')
        } else if (o && o.text && o.text === 'wellness_attendance_procedure') {
            download('AttendancesManagementProcedure')
        } else {

            if (o && o.name)
                setPathlistValue(o.name)
            switch (val) {

                case 'home':
                    setReload('yes')
                    setPathlistValue(null)
                    setRoutingObj(null)
                    setWebPathlist([])
                    if (!Object.is(o, null)) {
                        setHidden(!hidden)
                        setCustomOffice(o)
                        setSelectedOffice(o)
                    } else {
                        window.location.reload()
                    }
                    break;
                case 'offices':
                    setSelectedOffice(o)
                    if (o.name) {
                        /*
                        if (window.location.hostname === 'gucciforyou.gucci.com') {
                            if (o.name === 'WS.MODULE.LOCATION.MYLOCATION')
                                logEvent(analytics, 'NavigateToMyLocation');
                            else
                                logEvent(analytics, 'NavigateToLocation' + getTranslationByKey(o.name));
                        }
                                */
                        setPathlistValue(o.name)
                    }
                    break;
                case 'desk_booking':
                    /*
                    if (window.location.hostname === 'gucciforyou.gucci.com') {
                        logEvent(analytics, 'NavigateToDeskBooking');
                    }*/
                    break;
                case 'wellness':
                    if (window.location.hostname === 'gucciforyou.gucci.com') {
                        if (user.modules && user.modules.wellness_lab && (user.modules.wellness_lab.is_admin || user.modules.wellness_lab.is_trainer))
                            logEvent(analytics, 'NavigateToWellnessLabAdmin');
                        else
                            logEvent(analytics, 'NavigateToWellnessLab');
                    }
                    if (window.innerWidth > 1000)
                        setWebPathlist([])
                    break;

                case 'opportunities':
                    /*
                    if (window.location.hostname === 'gucciforyou.gucci.com') {
                        logEvent(analytics, 'NavigateToOpportunities');
                    }*/
                    setSelectedOffice(o)
                    setCustomOffice(o)
                    break;
                case 'opportunities_feedback_form':
                    /*
                    if (window.location.hostname === 'gucciforyou.gucci.com') {
                        logEvent(analytics, 'NavigateToOpportunitiesFeedbackForm');
                    }*/
                    setSelectedOffice(o)
                    setCustomOffice(o)
                    break;
                case 'custom_pages':
                    /*
                    if (window.location.hostname === 'gucciforyou.gucci.com' && webPathlist.length == 1) {
                        if (o.name) {
                            logEvent(analytics, 'NavigateTo' + getTranslationByKey(o.name));
                        }
                    }*/
                    if (where)
                        setComingFrom(where)
                    setSelectedOffice(o)
                    setCustomOffice(o)

                    break;
                case 'custom_pages_event':
                    /*
                    if (window.location.hostname === 'gucciforyou.gucci.com') {
                        if (o.name)
                            logEvent(analytics, 'NavigateTo' + getTranslationByKey(o.name));
                    }*/
                    setSelectedOffice(o)
                    setCustomOffice(o)
                    setIsEvent(true)
                    val = 'custom_pages'
                    break;
                case 'custom':
                    /*
                    if (window.location.hostname === 'gucciforyou.gucci.com') {
                        if (o.name)
                            logEvent(analytics, 'NavigateTo' + getTranslationByKey(o.name));
                    }*/
                    setCustomOffice(o)
                    setSelectedOffice(o)
                    break;
                case 'file_url':
                    setCustomOffice(o)
                    break;
                case 'wellness_file_url':
                    setCustomOffice(o)
                    break;
                case 'bookshelf_singlem':
                    /*
                    if (window.location.hostname === 'gucciforyou.gucci.com') {
                        logEvent(analytics, 'NavigateToBookshelfSingleModule');
                    }*/
                    if (window.innerWidth > 1000)
                        setWebPathlist([])
                    setSelectedOffice(o)
                    break;
                case 'ready_to_grow_singlem':
                    /*
                    if (window.location.hostname === 'gucciforyou.gucci.com') {
                        logEvent(analytics, 'NavigateToReadyToGrowSingleModule');
                    }*/
                    if (window.innerWidth > 1000)
                        setWebPathlist([])
                    setSelectedOffice(o)
                    break;

                case 'useful_info':
                    setCustomOffice(o)
                    break;

                case 'custom_module':
                    if (o.text) {
                        /*
                        if (window.location.hostname === 'gucciforyou.gucci.com') {
                            logEvent(analytics, "NavigateTo" + o.text);
                        }*/
                        setSelected(o.text)
                        val = o.text
                    }
                    if (o.module) {
                        /*
                        if (window.location.hostname === 'gucciforyou.gucci.com') {
                            logEvent(analytics, "NavigateTo" + o.module);
                        }*/
                        setCustomOffice(o.module)
                    } else
                        setCustomOffice(o)
                    //rompe canteen
                    //  setSelectedOffice(o)
                    break;
                case 'event':
                    setCustomOffice(o)
                    break;
                case 'final_page':
                    setCustomOffice(o)

                    break;
                case 'custom':
                    getDocument(o.file_url)
                    setSelected('custom_pages')
                    val = 'custom_pages'
                    setCustomOffice(o)

                    break;
                case 'feed':
                    /*
                    if (window.location.hostname === 'gucciforyou.gucci.com') {
                        logEvent(analytics, 'NavigateToVideoAndPodcasting');
                    }*/
                    if (window.innerWidth > 1000)
                        setWebPathlist([])
                    setCustomOffice(o)
                    break;
                default:
                    break;
            }
            if (o && o.tutorial)
                setTutorial(o.tutorial)
            setSelected(val)
        }
        setLoading(false)
    }
    const changeWhatsNewPage = (type) => {
        if (type === 'prev')
            setSelectedWhatsNewPage(selectedWhatsNewPage - 1)
        else {
            setSelectedWhatsNewPage(selectedWhatsNewPage + 1)
        }
    }
    const closeProfile = () => {
        ws.getUserInfov4().then((res) => {
            setUser(res)
        })
        setTimeout(() => {

            setProfileShow(false)
        }, 1000);
    }

    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: 'fit-content',
            height: '85vh',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#f0efef"
        },

    };
    let color = 'background: #000000;'

    /* tutorial */

    const openTutorialPopup = () => {
        setTutorialPopup(!tutorialPopup)
        setSelectedTutorialPage(0)
    }

    const changeTutorialPage = (type) => {
        if (type === 'prev')
            setSelectedTutorialPage(selectedTutorialPage - 1)
        else {
            setSelectedTutorialPage(selectedTutorialPage + 1)
        }
    }

    const handleSelect = (index) => {
        if (webPathlist.length > 0 && webPathlist[index] && webPathlist[index].type === 'event') {
            user.events.map((e) => {
                if (e.name === webPathlist[index].value) {
                    setWebPathlist([{ type: 'event', value: e.name }])

                    select('custom_pages_event', e)
                }
            })
        } else
            setWebPathlistSelected(index)
    }

    useEffect(() => {
        if (document.getElementsByTagName('body'))
            document.getElementsByTagName('body')[0].scrollTo(0, 0, 'smooth')
    }, [webPathlist])

    return (
        user && user.modules ?
            <React.Fragment>
                <Navbar openedOne={openedOne} getNotificationsNumber={getNotificationsNumber} notifications={notifications} closeNotificationBar={notificationBarStatus} unreadNotifications={unreadNotifications} notificationBar={notificationBar} notificationBarStatus={notificationBarStatus} app={props.app} from={props.from} sel_office={props.office} key='leftbar' openProfile={openProfile} closeProfile={closeProfile} profileShow={profileShow} user={user} selected={selected} select={(val, o) => select(val, o)} />
                {window.innerWidth < 1000 || !webPathlist || webPathlist.length == 0 ?
                    (window.innerWidth > 1000 && (selected === 'wellness' || selected === 'bookshelf_singlem' || selected === 'ready_to_grow_singlem' || selected === 'feed')
                        ? <WebPathlistSingleModule
                            selected={selected}
                            key={"webPathlistSingleModule"}
                            pathlist_value={pathlist_value}
                            webPathlist={webPathlist}
                            handleSelect={handleSelect}
                            tutorial={tutorial}
                            openTutorialPopup={openTutorialPopup}
                        /> : '') :
                    <WebPathlist
                        isOtherLocation={isOtherLocation}
                        key={"webPathlist"}
                        pathlist_value={pathlist_value}
                        webPathlist={webPathlist}
                        handleSelect={handleSelect}
                        tutorial={tutorial}
                        openTutorialPopup={openTutorialPopup} />}
                {window.innerWidth > 1000 && webPathlist.length == 0 && (selected !== 'wellness' && selected !== 'bookshelf_singlem' && selected !== 'ready_to_grow_singlem' && selected !== 'feed') ?
                    <div className="web_pathlist">
                        <div Style="max-width: 1345px;">
                            <div className=" web_pathlist_text">
                                {isOtherLocation ?
                                    <div className="flex bold" Style="align-content: center;">
                                        <div className="pointer bold" onClick={() => handleSelect(-1)}>{getTranslationByKey('Homepage')}</div>
                                        <div Style="margin: 0 5px;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                        </div>
                                        {getTranslationByKey('DESKBOOKING.OTHERLOCATIONS.TITLE')}
                                    </div>
                                    :
                                    <div className="flex bold" Style="align-content: center;">
                                        {getTranslationByKey('Homepage')}
                                    </div>
                                }
                            </div>
                        </div>
                    </div> : ''}

                <div className="home_page">

                    {window.innerWidth < 1000 && selected !== 'home' && pathlist_value ?
                        <Pathlist name={getTranslationByKey(pathlist_value)} tutorial={tutorial} openTutorialPopup={openTutorialPopup} /> : ''}
                    {window.innerWidth < 1000 && isOtherLocation && pathlist_value == null ?
                        <Pathlist name={getTranslationByKey("DESKBOOKING.OTHERLOCATIONS.TITLE")} tutorial={tutorial} openTutorialPopup={openTutorialPopup} /> : ''}
                    {window.innerWidth < 1000 && profileShow ?
                        <Pathlist name={"Profile"} tutorial={null} openTutorialPopup={openTutorialPopup} /> : ''}

                    {window.innerWidth < 1000 ?
                        <Sidebar setSubModuleId={setSubModuleId} reload={reload} setRoutingObj={setRoutingObj} pathlist_value={pathlist_value} setPathlistValue={setPathlistValue} unreadNotifications={unreadNotifications} notificationBar={notificationBar} notificationBarStatus={notificationBarStatus} app={props.app} from={props.from} sel_office={props.office} key='leftbar' openProfile={openProfile} user={user} selected={selected} select={(val, o) => select(val, o)} setHidden={setHidden} hiddenHomepage={hidden} openedOne={openedOne} getNotificationsNumber={getNotificationsNumber} notifications={notifications} closeNotificationBar={notificationBarStatus} webPathlist={webPathlist} setWebPathlist={setWebPathlist} setIsOtherLocation={setIsOtherLocation} webPathlistSelected={webPathlistSelected} closeProfile={closeProfile} profileShow={profileShow} setTutorial={setTutorial}></Sidebar>
                        :
                        <SidebarWeb setSubModuleId={setSubModuleId} reload={reload} setRoutingObj={setRoutingObj} pathlist_value={pathlist_value} setPathlistValue={setPathlistValue} unreadNotifications={unreadNotifications} notificationBar={notificationBar} notificationBarStatus={notificationBarStatus} app={props.app} from={props.from} sel_office={props.office} key='leftbar' openProfile={openProfile} user={user} selected={selected} select={(val, o) => select(val, o)} setHidden={setHidden} hiddenHomepage={hidden} openedOne={openedOne} getNotificationsNumber={getNotificationsNumber} notifications={notifications} closeNotificationBar={notificationBarStatus} webPathlist={webPathlist} setWebPathlist={setWebPathlist} setIsOtherLocation={setIsOtherLocation} webPathlistSelected={webPathlistSelected} />}
                    {profileShow ?
                        <div className="central_component">
                            {window.innerWidth < 1000 ? '' : <div className="tutorial_container"></div>}
                            <div className="central_component_width central_component_width_mobile">

                                <div className={"profile"} >
                                    <Profile closeProfile={closeProfile} user={user} getTranslationByKey={getTranslationByKey} logout={logout} />
                                </div>
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            <div className="central_component">
                                {window.innerWidth < 1000 ? ''
                                    :
                                    <div className="tutorial_container"></div>}
                                <CentralComponent subModuleId={subModuleId} routingObj={routingObj} comingFrom={comingFrom} webPathlist={webPathlist} setWebPathlist={setWebPathlist} isEvent={isEvent} setIsEvent={setIsEvent} setPathlistValue={setPathlistValue} pathlist_value={pathlist_value} selected={selected} custom_office={custom_office} office={selected_office} user={user} backToOffice={backToOffice} select={select} setHidden={setHidden} />
                            </div>
                            {profileShow ?
                                <div className="central_component">
                                    {window.innerWidth < 1000 ? '' : <div className="tutorial_container"></div>}
                                    <div className="central_component_width central_component_width_mobile">
                                        <div className={"profile"}>
                                            <Profile closeProfile={closeProfile} />
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        </React.Fragment>
                    }
                </div >
                <Dialog
                    open={whatsNew}
                    onClose={() => setWhatsNewPopup(false)}
                    style={customModalStyles}
                    className="whats_new_dialog"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="alert-dialog-title">
                        <div className="flex">
                            <div Style="font-family: 'GucciSans-Bold';">
                            </div>
                            <div class='close_whats_new pointer' Style="margin-left: auto;" onClick={() => setWhatsNewPopup(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </svg></div>
                        </div>
                    </DialogTitle>
                    <DialogContent>

                        <React.Fragment>
                            <div className="modal_body">
                                {user && whatsNewPages ? <div className="desk_tutorial_container">
                                    <div className=" item-center flex" Style={"justify-content: center;    display: flex;    align-items: center;"}>
                                        {selectedWhatsNewPage > 0 ?
                                            <div>
                                                <svg onClick={() => changeWhatsNewPage('prev')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left pointer" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                            </div>
                                            : <div Style={"width: 30px;"}></div>}
                                        <div className={user && whatsNewPages && whatsNewPages[selectedWhatsNewPage] && whatsNewPages[selectedWhatsNewPage].action && whatsNewPages[selectedWhatsNewPage].action.url !== '' ? "desk_tutorial_page pointer" : "desk_tutorial_page"}
                                            onClick={user && whatsNewPages && whatsNewPages[selectedWhatsNewPage] && whatsNewPages[selectedWhatsNewPage].action && whatsNewPages[selectedWhatsNewPage].action.url !== '' ?
                                                () => window.open(whatsNewPages[selectedWhatsNewPage].action.url, "_self")
                                                :
                                                ''}
                                            Style={"background-image: url('" + (whatsNewPages && whatsNewPages[selectedWhatsNewPage] && whatsNewPages[selectedWhatsNewPage].image_url ? whatsNewPages && whatsNewPages[selectedWhatsNewPage] && whatsNewPages[selectedWhatsNewPage].image_url : '') + "')"}
                                        >
                                        </div>
                                        {selectedWhatsNewPage < whatsNewPages.length - 1 ?
                                            <div>
                                                <svg onClick={() => changeWhatsNewPage('next')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-right pointer" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </div>
                                            : <div Style={"width: 30px;"}></div>}
                                    </div>
                                </div> : ''}
                            </div>
                        </React.Fragment>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={tutorialPopup}
                    onAfterOpen={null}
                    onClose={() => openTutorialPopup(false)}
                    style={customModalStyles}
                    className="tutorial_dialog"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>
                        <div className="close" >
                            <svg onClick={() => openTutorialPopup()} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {tutorial ?
                            <div className="modal_body">
                                <div className="desk_tutorial_container">
                                    <div className=" item-center flex" Style={"justify-content: center;    display: flex;    align-items: center;"}>
                                        {selectedTutorialPage > 0 ?
                                            <div>
                                                <svg onClick={() => changeTutorialPage('prev')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left pointer" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                            </div>
                                            : <div Style={"width: 30px;"}></div>}
                                        <div className="desk_tutorial_page" Style={"background-image: url('" + tutorial[selectedTutorialPage] + "')"}></div>
                                        {selectedTutorialPage < tutorial.length - 1 ?
                                            <div>

                                                <svg onClick={() => changeTutorialPage('next')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-right pointer" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </div>
                                            : <div Style={"width: 30px;"}></div>}
                                    </div>
                                </div>
                            </div> : ''}
                    </DialogContent>
                </Dialog>
            </React.Fragment >
            : (!loading && user && user.id && !user.modules ?
                <div className="login">
                    <div className="login-cont">
                        <div className="logo-container">
                            <div className="logo"></div>
                        </div>
                    </div>
                    <div className="input-container mb-4">
                        <p Style={"color: #fff"}>{getTranslationByKey('HOMEPAGE.NOTAUTHORIZED')}</p>
                    </div>
                    <div className="input-container">
                        <button className="login-button" onClick={() => logout()}>{getTranslationByKey('DESKBOOKING.PROFILE.LOGOUT')}</button>
                    </div>
                </div>
                :
                (loading ?
                    <div className='loader_ellipsis'>
                        <div class="lds-ellipsis">
                            <div Style={color}></div>
                            <div Style={color}></div>
                            <div Style={color}></div>
                            <div Style={color}></div>
                        </div>
                    </div>
                    : ''))
    )
}



