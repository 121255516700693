import Countdown from 'react-countdown';

export default function EventCountdownMenu(props) {

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        // Render a countdown
        return (
            <div >
                <div className="counter_string flex">
                    <div className="countdown_columns">
                        <div className="countdown_counter_text">{zeroPad(days, 2)} </div>
                        <div className="countdown_text" >days</div>
                    </div>

                    <div className="countdown_columns">
                        <div className="countdown_counter_text" > {zeroPad(hours, 2)}</div>
                        <div className="countdown_text" >hours</div>
                    </div>

                    <div className="countdown_columns">
                        <div className="countdown_counter_text" > {zeroPad(minutes, 2)}</div>
                        <div className="countdown_text" >minutes</div>
                    </div>

                    <div className="countdown_columns">
                        <div className="countdown_counter_text" > {zeroPad(seconds, 2)} </div>
                        <div className="countdown_text" >seconds</div>
                    </div>

                </div>
            </div>
        )
    };

    const zeroPad = (num, numZeros) => {
        var n = Math.abs(num);
        var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
        var zeroString = Math.pow(10, zeros).toString().substr(1);
        if (num < 0) {
            zeroString = '-' + zeroString;
        }
        return zeroString + n;
    }

    let date = ''
    if (props.event && props.event.countdown_image && props.event.countdown_image.countdown_date)
        date = props.event.countdown_image.countdown_date.split('T')[0]

    return (
        <div Style={"text-align: -webkit-center; margin-bottom: 10px;"}>
            <div Style={'position:relative'} >
                {props.event && props.event.countdown_image && props.event.countdown_image.countdown_date ?
                    <div className='countdown_container_string'>
                        <Countdown
                            date={new Date(props.event.countdown_image.countdown_date)}
                            renderer={renderer}
                        />
                    </div>
                    : ''}
            </div>
        </div >
    )
}