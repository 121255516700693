import React, { useEffect, useState } from "react";
import { OpportunitiesDetailHeader } from "./OpportunitiesDetailHeader"
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Modal from 'react-modal';
import { OpportunitiesService } from "../Services/Services";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Form } from "react-bootstrap";
import * as ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6
import "react-quill/dist/quill.core.css";
import { getTranslationByKey } from "../global";

export function OppotunitiesInterested(props) {

    const OS = new OpportunitiesService
    const [bookingsFiltered, setBookingsFiltered] = useState([])
    const [searching, setSearching] = useState('')
    const [openLineModal, setOpenLineModal] = useState(false)
    const [openLineIdList, setOpenLineIdList] = useState([])
    const [selectedLocationList, setSelectedLocationList] = useState([])
    const [locations, setLocations] = useState([])
    const [selectedStage, setSelectedStage] = useState('')
    const [stages, setStages] = useState([{ value: "starred", label: "Favorite" }, { value: "preferred", label: "Selected" }])
    const [selectedUser, setSelectedUser] = useState([])
    const [openSelectedModal, setOpenSelectedModal] = useState(false)
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false)
    const [feedbackText, setFeedbackText] = useState('')


    useEffect(() => {
        let l = []
        let selectList = []
        if (props.detail.bookings) {
            props.detail.bookings.map((b) => {
                l.push(b.location)
            })
            let tmp = l.filter(onlyUnique);
            tmp.map((t) => {
                selectList.push({ value: t, label: t })
            })
        }
        setLocations(selectList)
    }, [])

    useEffect(() => {
        setBookingsFiltered(props.detail.bookings)
    }, [props.detail.bookings])


    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    const handleOpenLineModal = (user) => {
        setSelectedUser(user)
        setOpenLineModal(true)
    }

    const getHeaderTableExperience = () => {
        let ret = ''
        ret = <tr>
            <td Style="background-color: #f5f5f7" className="bold">{getTranslationByKey('OPPORTUNITIES.LABLE.TABLE.NAME')}</td>
            <td Style="background-color: #f5f5f7" className="bold">{getTranslationByKey('OPPORTUNITIES.LABLE.TABLE.JOBROLE')}</td>
            <td Style="background-color: #f5f5f7" className="bold">{getTranslationByKey('OPPORTUNITIES.LABLE.TABLE.SLOT')}</td>
            <td Style="background-color: #f5f5f7"></td>
        </tr>
        return ret
    }

    const getHeaderTableProject = () => {
        let ret = ''
        ret = <tr>
            <td Style="background-color: #f5f5f7" className="bold"></td>
            <td Style="background-color: #f5f5f7" className="bold">{getTranslationByKey('OPPORTUNITIES.LABLE.TABLE.NAME')}</td>
            <td Style="background-color: #f5f5f7" className="bold">{getTranslationByKey('OPPORTUNITIES.LABLE.TABLE.JOBROLE')}</td>
            <td Style="background-color: #f5f5f7" colSpan={4}></td>
        </tr>
        return ret
    }

    const setStarred = (id) => {
        OS.setStarred(props.detail.info.id, id).then((data) => {
            props.reloadTable()
        })
    }
    const setMeeting = (id) => {
        OS.setMeeting(props.detail.info.id, id).then((data) => {
            props.reloadTable()
        })
    }
    const handleSelected = (user) => {
        setSelectedUser(user)
        setOpenSelectedModal(true)
    }
    const setSelected = (id) => {
        OS.setSelected(props.detail.info.id, id).then((data) => {
            setOpenSelectedModal(false)
            props.reloadTable()
        })
    }
    const setPresent = (id) => {
        OS.setPresent(props.detail.info.id, id).then((data) => {
            props.reloadTable()
        })
    }
    const sendFeedbackEmail = () => {
        let obj = {
            recipient_id: selectedUser.id,
            mail_body: feedbackText
        }
        OS.sendFeedbackEmail(props.detail.info.id, obj).then((data) => {
            setOpenFeedbackModal(false)
            props.reloadTable()
        }).finally(() => {
            setFeedbackText('')
            setOpenFeedbackModal(false)
            props.reloadTable()
        })

    }
    const showFeedbackForm = (user) => {
        setSelectedUser(user)
        setOpenFeedbackModal(true);
        if (props.detail.email_template.text_placeholder)
            setFeedbackText(props.detail.email_template.text_placeholder)
    }

    const presentTooltip = <Popover id="popover-basic" Style={'box-shadow: "-1px 2px 5px rgba(0,0,0,0.1)"'}>
        <div Style={'padding:10px'}>
            {getTranslationByKey('OPPORTUNITIES.LABLE.PRESENT')}
        </div>
    </Popover>
    const getTableExperience = () => {
        let ret = ''
        ret =
            bookingsFiltered.map((b) =>
                <React.Fragment>
                    <tr>
                        <td >
                            <div className="flex vertical_center" Style="font-size: 14px;">
                                <div className="opportunities_table_user_icon"></div>
                                <div>
                                    <div>{b.first_name} {b.last_name}</div>
                                    <div>{b.email}</div>
                                </div>
                            </div>
                        </td>
                        <td Style="font-size: 14px;"><div className="job_role_cell">{b.job_role ?? '-'}</div></td>
                        <td Style="font-size: 14px;">
                            {b.is_in_waiting_list ? 'In waiting list' : b.slot.slot_dates}
                        </td>
                        <td className="opportunities_table_icon_cell pointer" onClick={() => setPresent(b.id)}>

                            {b.present ?
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    rootClose
                                    overlay={presentTooltip}
                                >
                                    <Form.Text className="show_more">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg>
                                    </Form.Text>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    rootClose
                                    overlay={presentTooltip}
                                >
                                    <Form.Text className="show_more">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                        </svg>
                                    </Form.Text>
                                </OverlayTrigger>
                            }
                        </td>
                    </tr>
                    {openLineIdList.length > 0 && openLineIdList.includes(b.id) ?
                        <tr className="opportunities_question_table">
                            <td colSpan={4} >
                                {b.form.map((q) =>
                                    <React.Fragment>
                                        <div className="bold" Style="font-size: 14px;">{q.question}</div>
                                        <div Style="font-size: 14px;">{q.answer}</div>
                                    </React.Fragment>)}
                            </td>

                        </tr>
                        : ''}

                </React.Fragment>

            )

        return ret
    }

    const viewMoreTooltip = <Popover id="popover-basic" Style={'box-shadow: "-1px 2px 5px rgba(0,0,0,0.1)"'}>
        <div Style={'padding:10px'}>
            {getTranslationByKey('OPPORTUNITIES.LABLE.VIEWMORE')}
        </div>
    </Popover >
    const favTooltip = <Popover id="popover-basic" Style={'box-shadow: "-1px 2px 5px rgba(0,0,0,0.1)"'}>
        <div Style={'padding:10px'}>
            {getTranslationByKey('OPPORTUNITIES.LABLE.FAVOURITE')}
        </div>
    </Popover >

    const meetingTooltip = <Popover id="popover-basic" Style={'box-shadow: "-1px 2px 5px rgba(0,0,0,0.1)"'}>
        <div Style={'padding:10px'}>
            {getTranslationByKey('OPPORTUNITIES.LABLE.MEETING')}
        </div>
    </Popover >
    const selectedTooltip = <Popover id="popover-basic" Style={'box-shadow: "-1px 2px 5px rgba(0,0,0,0.1)"'}>
        <div Style={'padding:10px'}>
            {getTranslationByKey('OPPORTUNITIES.LABLE.SELECTUSER')}
        </div>
    </Popover >
    const feedbackTooltip = <Popover id="popover-basic" Style={'box-shadow: "-1px 2px 5px rgba(0,0,0,0.1)"'}>
        <div Style={'padding:10px'}>
            {getTranslationByKey('OPPORTUNITIES.LABLE.SENDFEEDBACK')}
        </div>
    </Popover >

    const getTableLinesProject = () => {
        let ret = ''
        ret =
            bookingsFiltered.map((b, index) =>
                <React.Fragment>
                    <tr className="pointer">
                        <td onClick={() => handleOpenLineModal(b)}>
                            <div>
                                <div className="opportunities_table_user_icon">
                                    <OverlayTrigger
                                        placement="top"
                                        trigger="hover"
                                        rootClose
                                        overlay={viewMoreTooltip}
                                    >
                                        <Form.Text className="show_more">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                            </svg>
                                        </Form.Text>
                                    </OverlayTrigger>

                                </div>
                            </div>
                        </td>
                        <td>
                            <div>
                                <div>{b.first_name} {b.last_name}</div>
                                <div>{b.email}</div>
                            </div>
                        </td>
                        <td Style="font-size: 14px;">
                            <div className="job_role_cell">{b.job_role ?? '-'}</div></td>
                        <td className="opportunities_table_icon_cell  pointer" onClick={() => setStarred(b.id)}>
                            {b.starred ?
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    rootClose
                                    overlay={favTooltip}
                                >
                                    <Form.Text className="show_more">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg>
                                    </Form.Text>
                                </OverlayTrigger>

                                :
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    rootClose
                                    overlay={favTooltip}
                                >
                                    <Form.Text className="show_more">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                                        </svg>
                                    </Form.Text>
                                </OverlayTrigger>

                            }
                        </td>
                        <td className="opportunities_table_icon_cell pointer" onClick={() => setMeeting(b.id)}>
                            {b.meeting ?
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    rootClose
                                    overlay={meetingTooltip}
                                >
                                    <Form.Text className="show_more">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                                            <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2m-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                        </svg>
                                    </Form.Text>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    rootClose
                                    overlay={meetingTooltip}
                                >
                                    <Form.Text className="show_more">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                        </svg>
                                    </Form.Text>
                                </OverlayTrigger>
                            }
                        </td>
                        <td className="opportunities_table_icon_cell pointer" onClick={!b.selected ? () => handleSelected(b) : null}>
                            {b.selected ?
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    rootClose
                                    overlay={selectedTooltip}
                                >
                                    <Form.Text className="show_more">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg>
                                    </Form.Text>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    rootClose
                                    overlay={selectedTooltip}
                                >
                                    <Form.Text className="show_more">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                        </svg>
                                    </Form.Text>
                                </OverlayTrigger>
                            }
                        </td>
                        <td className="opportunities_table_icon_cell pointer" onClick={() => showFeedbackForm(b)}>
                            {b.feedback_sent ?
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    rootClose
                                    overlay={feedbackTooltip}
                                >
                                    <Form.Text className="show_more">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16" >
                                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                                        </svg>
                                    </Form.Text>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    rootClose
                                    overlay={feedbackTooltip}
                                >
                                    <Form.Text className="show_more">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                        </svg>
                                    </Form.Text>
                                </OverlayTrigger>
                            }
                        </td>
                    </tr>
                    {
                        openLineIdList.length > 0 && openLineIdList.includes(b.id) ?
                            <tr className="opportunities_question_table">
                                <td colSpan={"100%"} >
                                    {b.form.map((q) =>
                                        <React.Fragment>
                                            <div className="bold" Style="font-size: 14px;">{q.question}</div>
                                            <div Style="font-size: 14px;">{q.answer}</div>
                                        </React.Fragment>)}
                                </td>

                            </tr>
                            : ''
                    }

                </React.Fragment >

            )

        return ret
    }

    const handleSearch = (e) => {
        setSearching(e.target.value)
        let tmp = []
        tmp = (props.detail.bookings.filter(r => r.last_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            (r.last_name.toLowerCase() + ' ' + r.first_name.toLowerCase()).includes(e.target.value.toLowerCase()) ||
            (r.first_name.toLowerCase() + ' ' + r.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) ||
            r.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || r.email.toLowerCase().includes(e.target.value.toLowerCase())
        ))
        setBookingsFiltered(tmp)
    }

    const handleChangeLocation = (e) => {
        setSelectedLocationList(e)
    }

    const handleChangeStage = (e) => {
        setSelectedStage(e)

    }

    useEffect(() => {
        let tmp = []
        if (selectedStage) {
            if (selectedStage.value === 'starred') {
                tmp = (props.detail.bookings.filter(r => r.starred == true))
            }
            else {
                tmp = (props.detail.bookings.filter(r => r.selected == true))
            }
            setBookingsFiltered(tmp)
        }
        if (selectedLocationList && selectedLocationList.length > 0) {

            selectedLocationList.map((sl) => {
                props.detail.bookings.map((b) => {
                    if (b.location === sl.value)
                        tmp.push(b)
                })
            })

            setBookingsFiltered(tmp)
        }
        if (searching) {
            tmp = (props.detail.bookings.filter(r => r.last_name.toLowerCase().includes(searching.toLowerCase()) ||
                (r.last_name.toLowerCase() + ' ' + r.first_name.toLowerCase()).includes(searching.toLowerCase()) ||
                (r.first_name.toLowerCase() + ' ' + r.last_name.toLowerCase()).includes(searching.toLowerCase()) ||
                r.first_name.toLowerCase().includes(searching.toLowerCase()) || r.email.toLowerCase().includes(searching.toLowerCase())
            ))
            setBookingsFiltered(tmp)
        }
        if (!searching && selectedLocationList.length == 0 && !selectedStage) {
            setBookingsFiltered(props.detail.bookings)
        }
    }, [selectedStage, selectedLocationList, searching])

    const getHtml = (val) => {
        return <div Style={'width: 600px;'} dangerouslySetInnerHTML={{ __html: val }}></div>
    }

    const customStylesServices = {
        control: (base, { isFocused, isSelected }) => ({
            ...base,
            background: window.innerWidth > 1000 ? "#fff" : "#f0efef",
            minHeight: '35px',
            height: '35px',
            border: 0,
            // Overwrittes the different states of border
            borderColor: isSelected ? null : null,
            // Removes weird border around container
            boxShadow: isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '35px',
            padding: '0 6px'
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '35px',
        }),
        option: (styles, { isFocused, isHovered }) => ({
            ...styles,
            backgroundColor: isFocused || isHovered ? "#c5c5c7" : "transparent",
            color: "black",
            "&:hover": {
                backgroundColor: "#c5c5c7"
            },
            color: '#000',
            zIndex: 1
        }),
        menuList: (base, { isFocused, isSelected }) => ({
            ...base,
            background: window.innerWidth > 1000 ? "#fff" : "#f0efef",
            // kill the white space on first and last option
            padding: 0,
            "max-height": "220px",
            "::-webkit-scrollbar-thumb": {
                background: "#000 !important"
            },
        })
    };


    let customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '600px',
            height: '365px',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": window.innerWidth > 1000 ? "#fff" : "#f0efef"
        }
    };

    let customFeedbackModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '650px',
            //height: 'calc(100vh - 150px)',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": window.innerWidth > 1000 ? "#fff" : "#f0efef"
        }
    };

    const Quill = ReactQuill.Quill
    var Font = Quill.import('formats/font');

    Font.whitelist = ['Gucci-Book', 'Arial', 'Arial-Black', 'Calibri', 'Calibri-Light', 'Cambria', 'Candara', 'Century-Gothic', 'Comic-Sans-MS', 'Consolas', 'Constantia', 'Corbel', 'Courier-New',
        'Franklin-Gothic-Book', 'Franklin-Gothic-Demi', 'Franklin-Gothic-Medium', 'Garamond', 'Georgia', 'Gucci-Bold', 'Impact', 'Lucida-Console', 'Lucida-Handwriting',
        'Lucida-Sans-Unicode', 'Palatino-Linotype', 'Segoe-UI', 'Sitka-Heading', 'Tahoma', 'Times', 'Times-New-Roman', 'Trebuchet-MS', 'TW-Cen-MT', 'Verdana'];
    Quill.register(Font, true);
    //  Quill.register('modules/ImageResize', ImageResize);

    //  Quill.register(quillTable.Contain);
    //  Quill.register('modules/table', quillTable.TableModule);
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] },
                "bold", "italic", "underline", { color: [] }, { background: [] }],
            // strike
            //  [{ color: [] }, { background: [] }],
            /*   [{ script: "sub" }, { script: "super" }],
               ["blockquote", "code-block"],*/
            // [{ color: [] }, { background: [] }, { list: "ordered" }, { list: "bullet" }],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }, "link", "attachment"],
            //  ["link", "image"],

        ],
        /*  attachmentHandler: {


              upload: file => {
                  setTimeout(() => {
                      if (file.type === 'image/jpeg') {
                          // return a Promise that resolves in a link to the uploaded image
                          return new Promise((resolve, reject) => {
                              fetch('https://httpbin.org/post', {
                                  method: 'POST',
                                  body: file,
                                  // 👇 Set headers manually for single file upload
                                  headers: {
                                      'content-type': file.type,
                                      'content-length': file.size, // 👈 Headers need to be a string
                                  },
                              })
                                  .then(data => {
                                      this.setState({
                                          file: file
                                      })
                                      resolve(data)
                                  }).catch((err) => {
                                      reject(err)
                                  })
                          });
                      }
                      else {
                          UseAlertMessage(404, 'File extension not valid')
                      }
                  }, 500);
              }
          }*/


        //image-resize
        /* ImageResize: {
             displaySize: true,
             resize: true
         }
        */
    }

    return (
        <div>
            <div className="flex">
                <div className="opportunities_detail_cont">
                    <OpportunitiesDetailHeader handleDuplicateView={props.handleDuplicateView} handleRemoveOpportunity={props.handleRemoveOpportunity} detail={props.detail} getData={props.getData} customStylesServices={customStylesServices} setUpdateView={props.setUpdateView} reload={props.reload} />
                    <div Style="padding: 20px;">

                        <div className="flex">
                            <div>
                                <svg id="search-icon" class="search-icon" viewBox="0 0 24 24">
                                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                    <path d="M0 0h24v24H0z" fill="none" />
                                </svg>
                                <input className="opportunities_search_input" id='opportunities_search_input' placeholder="Search by name or email" onChange={(e) => handleSearch(e)} value={searching} />
                            </div>
                            <div>
                                <MultiSelect
                                    key={''}
                                    className={"opportunities_interested_select opportunities_interested_select_location"}
                                    options={locations}
                                    value={selectedLocationList}
                                    onChange={(e) => handleChangeLocation(e)}
                                    placeholder={"Location"}
                                    valueRenderer={() => {
                                        return selectedLocationList.length > 0
                                            ? selectedLocationList.length + ' Selected'
                                            : (selectedLocationList.length > 0 ? selectedLocationList.map(({ label }, index) => index < selectedLocationList.length && index != 0 ? ',' + label : label) : props.label);
                                    }}
                                    isCreatable={false}
                                    hasSelectAll={true}
                                />

                            </div>
                            {props.detail.info.type.id == 2 ?
                                ''
                                :
                                <div>
                                    <Select
                                        value={selectedStage}
                                        onChange={handleChangeStage}
                                        options={stages}
                                        className="opportunities_interested_select pt-1"
                                        placeholder={"Stage"}
                                        styles={customStylesServices}
                                        isClearable={true}
                                    />
                                </div>
                            }
                        </div>
                        <div className="opportunities_table_legend">

                            <div className="flex ">
                                <div className="flex vertical_center">
                                    <div Style="margin-bottom: 2px;">

                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                                        </svg>
                                    </div>
                                    <div Style="margin-left: 5px;    font-size: 14px;">
                                        {getTranslationByKey('OPPORTUNITIES.LABLE.FAVOURITE')}
                                    </div>
                                </div>

                                <div className="flex vertical_center" Style="margin-left: 20px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                    </svg>
                                    <div Style="margin-left: 5px;    font-size: 14px;">
                                        {getTranslationByKey('OPPORTUNITIES.LABLE.MEETING')}
                                    </div>
                                </div>
                                <div className="flex vertical_center" Style="margin-left: 20px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                    </svg>
                                    <div Style="margin-left: 5px;    font-size: 14px;">
                                        {getTranslationByKey('OPPORTUNITIES.LABLE.SELECTUSER')}
                                    </div>
                                </div>
                                <div className="flex vertical_center" Style="margin-left: 20px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                    </svg>
                                    <div Style="margin-left: 5px;    font-size: 14px;">
                                        {getTranslationByKey('OPPORTUNITIES.LABLE.SENDFEEDBACK')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="opportunities_interested_table">
                            {props.detail.info.type.id == 2 ? getHeaderTableExperience() : getHeaderTableProject()}
                            {bookingsFiltered && bookingsFiltered.length > 0 ?
                                props.detail.info.type.id == 2 ? getTableExperience() : getTableLinesProject()
                                :
                                <tr>
                                    <td colSpan="100%">{getTranslationByKey('OPPORTUNITIES.LABLE.NOUSERS')}</td>
                                </tr>
                            }
                        </table>

                    </div>
                </div>

            </div>

            <Modal
                isOpen={openLineModal}
                onAfterOpen={null}
                onRequestClose={() => setOpenLineModal(false)}
                style={customModalStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root') || undefined}
            >
                <div className="close" >
                    <svg onClick={() => setOpenLineModal(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <div className="modal_body" Style="overflow: auto; height: 35vh;">
                    <div Style="margin: 40px 0;">
                        {selectedUser && selectedUser.form ? selectedUser.form.map((q, dim) =>
                            <React.Fragment>
                                {dim == 0 ? '' : <hr />}
                                <div className="bold" Style="font-size: 14px;">{getTranslationByKey(q.question)}</div>
                                <div Style="font-size: 14px;">{q.answer}</div>
                            </React.Fragment>) : ''}
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={openSelectedModal}
                onAfterOpen={null}
                onRequestClose={() => setOpenSelectedModal(false)}
                style={customModalStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root') || undefined}
            >
                <div className="close" >
                    <svg onClick={() => setOpenSelectedModal(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <div className="modal_body">
                    <div Style="margin: 40px 0;">
                        {getTranslationByKey('OPPORTUNITIES.LABLE.CONFIRMSELECTION')}
                    </div>
                    <div className="opportunities_action_button_container">
                        <div className="opportunities_action_button bold pointer flex" onClick={() => setSelected(selectedUser.id)}>
                            <div className="bold">
                                {getTranslationByKey('OPPORTUNITIES.LABLE.CONFIRM')}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={openFeedbackModal}
                onAfterOpen={null}
                onRequestClose={() => setOpenFeedbackModal(false)}
                class={"opportunities_send_email_modal"}
                style={customFeedbackModalStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root') || undefined}
            >
                <div className="close" >
                    <svg onClick={() => setOpenFeedbackModal(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <div className="modal_body">

                    {props.detail && props.detail.email_template && props.detail.email_template.header ? getHtml(props.detail.email_template.header) : ''}
                    <div Style="margin: 20px 0;width: 600px;">
                        <ReactQuill
                            theme='snow'
                            className='test-editor opportunities_send_email'
                            onChange={(val) => setFeedbackText(val)}

                            modules={modules}
                            value={feedbackText}
                        />
                    </div>
                    {props.detail && props.detail.email_template && props.detail.email_template.footer ? getHtml(props.detail.email_template.footer) : ''}
                    <div className="opportunities_action_button_container">
                        <div className="opportunities_action_button bold pointer flex" Style="margin-top: 60px;" onClick={() => sendFeedbackEmail()}>
                            <div  >
                                {getTranslationByKey('OPPORTUNITIES.QUESTIONARY.SEND')}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}